import PlayRandomMoveEngine from './PlayRandomMoveEngine'
import './App.css';

function App() {
  return (
    <div>
      <PlayRandomMoveEngine />
    </div>
  );

}

export default App;
