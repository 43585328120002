import { useState } from "react";
import { Chess } from "chess.js";
import { Chessboard } from "react-chessboard";

const game = new Chess();

export default function PlayRandomMoveEngine() {
  const [moves, setMoves] = useState([]);

  function makeAMove(move) {
    const result = game.move(move);
    setMoves([...moves, move]);
    return result; // null if the move was illegal, the move object if the move was legal
  }

  function makeRandomMove() {
    const possibleMoves = game.moves();
    if (game.isGameOver() || game.isDraw() || possibleMoves.length === 0)
      return; // exit if the game is over
    const randomIndex = Math.floor(Math.random() * possibleMoves.length);
    makeAMove(possibleMoves[randomIndex]);
  }

  function onDrop(sourceSquare, targetSquare, piece) {
    const move = {
      from: sourceSquare,
      to: targetSquare,
      // promotion: 'q' // always promote to a queen for example simplicity
    };

    if (
      (piece === "wP" && targetSquare.match(/8/)) ||
      (piece === "bP" && targetSquare.match(/1/))
    ) {
      move.promotion = "q";
    }

    const result = makeAMove(move);

    if (result === null)
      // illegal move
      return false;

    setTimeout(makeRandomMove, 200);
    return true;
  }

  return (
    <div style={{ display: "grid", placeItems: "center" }}>
      <Chessboard position={game.fen()} onPieceDrop={onDrop} />
    </div>
  );
}
